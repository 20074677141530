export * from "@jolinus/components";

document.addEventListener("DOMContentLoaded", () => {
  const data: string | undefined = findGetParameter("d");
  console.log(data);
  if (data !== undefined) {
    console.log(atob(data).split(",")[1]);
  }

  const arrow: HTMLDivElement = document.querySelector(
    "#deny-arrow"
  ) as HTMLDivElement;
  arrow.addEventListener("click", toggleDeny);
});

function findGetParameter(parameterName: string): string | undefined {
  var result = undefined,
    tmp = [];
  location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

var isOpen: boolean = false;
function toggleDeny(): void {
  const arrow: HTMLDivElement = document.querySelector(
    "#deny-arrow"
  ) as HTMLDivElement;
  if (isOpen) {
    arrow.classList.remove("arrow-open");
  } else {
    arrow.classList.add("arrow-open");
  }
  isOpen = !isOpen;
}
